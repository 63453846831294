@import url("https://fonts.googleapis.com/css2?family=Passero+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alatsi&family=Passero+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-animate-color {
  animation: colorChange 3s infinite;
}

@keyframes colorChange {
  0% {
    background-color: violet;
  }
  25% {
    background-color: red;
  }
  50% {
    background-color: purple;
  }
  75% {
    background-color: blue;
  }
  100% {
    background-color: violet;
  }
}

@layer base {
  body {
    @apply bg-warm-white;
  }

  .current-stash-transform {
    transform-origin: left center;
    /*transform: rotateY(-15deg) rotate(16deg) rotateX(2deg);*/
  }

  .current-bonus-transform {
    transform-origin: left center;
    transform: rotateY(2deg) rotate(-4deg) rotateX(-12deg);
  }

  .money-amount-transform {
    margin-left: calc(0.1vw - 14px);
    transform-origin: left center;
    /*transform: rotateY(-22deg) rotate(16deg) rotateX(2deg);*/
  }
}

.landing-container {
  text-align: center;
}

.hero-container {
  position: relative;
  z-index: 10;
}

.sticky-image {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

@layer components {
  .scrollbar::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #c4bcc8;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #1e3557;
    border-radius: 100vh;
    border: 3px solid #c4bcc8;
  }
}

@font-face {
  font-family: "FamiljenGrotesk";
  src: local("FamiljenGrotesk"), url("./assets/font/FamiljenGrotesk.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "FamiljenGrotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-btn-primary,
span {
  display: inline-flex;
  align-items: center;
}

/* For Roshambo */
.animate-scroll {
  animation: scroll 3s linear forwards;
}

@keyframes scroll {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.animate-spin2 {
  animation: spin2 3s linear;
}

@keyframes spin2 {
  to {
    transform: rotate(360deg);
  }
}
